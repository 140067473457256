import {Anchor, Content, PageTitle, Paragraph, Section, SectionTitle, Spacer} from "../../styles/content";
import {Helmet} from "react-helmet-async";
import styled from 'styled-components';
import { FooterImage } from "../../components/footer-image";

import FooterImageAvif2160 from "../../images/footer-rates-2160.avif";
import FooterImageAvif1440 from "../../images/footer-rates-1440.avif";
import FooterImageAvif720 from "../../images/footer-rates-720.avif";
import FooterImageAvif480 from "../../images/footer-rates-480.avif";
import FooterImageWebp2160 from "../../images/footer-rates-2160.webp";
import FooterImageWebp1440 from "../../images/footer-rates-1440.webp";
import FooterImageWebp720 from "../../images/footer-rates-720.webp";
import FooterImageWebp480 from "../../images/footer-rates-480.webp";
import FooterImageJpg2160 from "../../images/footer-rates-2160.jpg";
import FooterImageJpg1440 from "../../images/footer-rates-1440.jpg";
import FooterImageJpg720 from "../../images/footer-rates-720.jpg";
import FooterImageJpg480 from "../../images/footer-rates-480.jpg";

const InfoBlock = styled.div`
    background-color: #f5f5ff;
    padding: 1.5rem;
    border-radius: 4px;
    margin: 1.5rem 0;

    h4 {
        color: #326933;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 0.5rem;
    }
`;

const PostersContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin: 2rem 0;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }

    img {
        width: 100%;
        height: auto;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }
`;

export function Groepsaanbod() {
    return (
        <Content>
            <Helmet>
                <title>De Spiegel - Groepsaanbod</title>
                <meta name="description"
                      content="Ontdek ons groepsaanbod bij De Spiegel. We bieden verschillende groepssessies aan, waaronder Zorg voor Zelfzorg en Oudertraining en Ontmoetingsgroep."/>
            </Helmet>
            <PageTitle>
                Groepsaanbod
            </PageTitle>
            
            <Section>
                <Paragraph mb={1}>
                    Bij De Spiegel bieden we verschillende groepssessies aan. Deze sessies zijn een waardevolle aanvulling op individuele therapie of kunnen ook op zichzelf staand gevolgd worden.
                </Paragraph>
            </Section>
            
            <Spacer />
            
            <Section>
                <SectionTitle>
                    Zorg voor Zelfzorg
                </SectionTitle>
                <Paragraph mb={1}>
                    In deze tijd van drukte, gejaag en onszelf voorbij lopen kennen we allemaal 'stress'. Maar wist je dat er goede en slechte 'stress' is? Of dat zelfzorg je stressbestendigheid kan verhogen?
                </Paragraph>
                <Paragraph mb={1}>
                    Deze cursus maakt je wegwijs in stress en hoe het verband houdt met angst, assertiviteit, piekeren en perfectionisme.
                </Paragraph>
                <Paragraph mb={2}>
                    Hoog tijd voor meer zorg voor jouw zelfzorg!
                </Paragraph>
                
                <InfoBlock>
                    <h4>Praktische Info</h4>
                    <p><strong>Door wie:</strong> Tanja Blondeel (ELP) en Karima Boutliss (psychotherapeute)</p>
                    <p><strong>Deelnameprijs:</strong> €12,50 voor 5 sessies (conventie eerstelijnspsychologische zorg)</p>
                    <p><strong>Waar:</strong> MCHM, Koning Albertlaan 35, 3630 Maasmechelen</p>
                    <p><strong>Wanneer:</strong> vrijdag 07/02, 21/02, 07/03, 21/03 en 04/04</p>
                    <p><strong>Tijdstip:</strong> telkens van 9u30 tot 11u30</p>
                    <p><strong>Meer info en inschrijven:</strong> via <Anchor href="mailto:psycholoog.tanja@gmail.com">psycholoog.tanja@gmail.com</Anchor></p>
                </InfoBlock>
            </Section>
            
            <Spacer />
            
            <Section>
                <SectionTitle>
                    Oudertraining en Ontmoetingsgroep
                </SectionTitle>
                <Paragraph mb={1}>
                    Opvoeden is een hele uitdaging. Soms loopt het niet zoals je zou willen en weet je niet goed hoe je ermee om moet gaan. Herken je dit? Dan is deze oudertraining iets voor jou!
                </Paragraph>
                <Paragraph mb={1}>
                    In deze groep leer je over opvoeding en krijg je handvaten aangereikt om met moeilijke situaties om te gaan. Daarnaast is er ruimte om andere ouders te ontmoeten en ervaringen uit te wisselen.
                </Paragraph>
                <Paragraph mb={2}>
                    Samen staan we sterker!
                </Paragraph>

                <InfoBlock>
                    <h4>Praktische Info</h4>
                    <p><strong>Door wie:</strong> Tanja Blondeel (ELP) en Karima Boutliss (psychotherapeute)</p>
                    <p><strong>Deelnameprijs:</strong> €12,50 voor 5 sessies (conventie eerstelijnspsychologische zorg)</p>
                    <p><strong>Waar:</strong> MCHM, Koning Albertlaan 35, 3630 Maasmechelen</p>
                    <p><strong>Wanneer:</strong> vrijdag 07/02, 21/02, 07/03, 21/03 en 04/04</p>
                    <p><strong>Tijdstip:</strong> telkens van 13u30 tot 15u30</p>
                    <p><strong>Meer info en inschrijven:</strong> via <Anchor href="mailto:psycholoog.tanja@gmail.com">psycholoog.tanja@gmail.com</Anchor></p>
                </InfoBlock>
            </Section>

            <Spacer />

            <Section>
                <SectionTitle>
                    Posters
                </SectionTitle>
                <PostersContainer>
                    <img src={require("../../images/zorg-voor-zelfzorg.jpg")} alt="Zorg voor Zelfzorg cursus informatie" />
                    <img src={require("../../images/oudertraining-en-ontmoetingsgroep.jpg")} alt="Oudertraining en Ontmoetingsgroep informatie" />
                </PostersContainer>
            </Section>
            <Spacer/>
            <FooterImage avifImages={{xl: FooterImageAvif2160, lg: FooterImageAvif1440, sm: FooterImageAvif720, xs: FooterImageAvif480}}
                            webpImages={{xl: FooterImageWebp2160, lg: FooterImageWebp1440, sm: FooterImageWebp720, xs: FooterImageWebp480}}
                            jpgImages={{xl: FooterImageJpg2160, lg: FooterImageJpg1440, sm: FooterImageJpg720, xs: FooterImageJpg480}}
            />
        </Content>
    );
}